.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
  padding: 18px 20px;
  display: inline-block;
}

.promoFooter {
  margin-top: 0vh;
  margin-bottom: 0vh;
  color: white;
  background-image: linear-gradient(to right, rgb(70, 68, 68), rgb(20, 19, 19));
}


/* .media {
  margin: 0 auto;
  color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0.8;
  border-radius: 0;
  transition: background-image 1s ease-in-out ease-in;
  background-image: url(./Images/media3.PNG);
} */

.neu-button-top {
  min-height: 20vh;
  min-width: 20vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: -16px 16px 32px #131212,
    0px -16px 32px #4b4848;
}

.neu-button-small {
  min-height: 12vh;
  min-width: 12vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: inset -7px 7px 14px #131212,
    inset 7px -7px 14px #4b4848;
}

.neu-square {
  border-radius: 0px;
  background: #2f2d2d;
  box-shadow: inset -7px 7px 14px #131212,
    inset 7px -7px 14px #4b4848;
}

.neu-button-right {
  min-height: 20vh;
  min-width: 20vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: -16px 16px 32px #131212,
    16px -16px 32px #4b4848;
}

.neu-button-left {
  min-height: 20vh;
  min-width: 20vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: 16px 16px 32px #131212,
    -16px -16px 32px #4b4848;
}

.neu-button-bottom-right {
  min-height: 20vh;
  min-width: 20vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: -16px -16px 32px #131212,
    16px 16px 32px #4b4848;
}

.neu-button-bottom-left {
  min-height: 20vh;
  min-width: 20vh;
  border-radius: 50%;
  background: #2f2d2d;
  box-shadow: 16px -16px 32px #131212,
    -16px 16px 32px #4b4848;
}

.neu-get-started {
  min-height: 12vh;
  min-width: 12vh;
  border-radius: 50%;
  background: linear-gradient(145deg, #1c1b1b, #212020);
  box-shadow: 18px 18px 36px #0c0c0c,
    -18px -18px 36px #323030;
}



.auth_image {
  margin: 0 auto;
  color: white;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 1;
  transition: background-image 2s ease-in-out;
  background-image: linear-gradient(to right, rgb(70, 68, 68), rgb(20, 19, 19));
}

.ripple-container {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition: background-image 2s ease-in-out;
  background-image: linear-gradient(to right, rgb(70, 68, 68), rgb(20, 19, 19));
}

.ripple-button {
  animation: ripple 10s infinite;
  border-radius: 100%;
  border: none;
  background-color: #2f2d2d;
  box-shadow: inset 10px 10px 20px #2f2d2d, inset -10px -10px 20px #131212;
  transition: 0.33s ease-in all;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.ripple-button2 {
  animation: ripple 12s infinite;
  border-radius: 100%;
  border: none;
  background-color: #2f2d2d;
  box-shadow: inset 10px 10px 20px #2f2d2d, inset -10px -10px 20px #131212;
  transition: 0.33s ease-in all;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.ripple-button3 {
  animation: ripple 14s infinite;
  border-radius: 100%;
  border: none;
  background-color: #2f2d2d;
  box-shadow: inset 10px 10px 20px #2f2d2d, inset -10px -10px 20px #131212;
  transition: 0.33s ease-in all;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

@keyframes ripple {
  0% {
    width: 0px;
    height: 0px;
    opacity: 1;
  }

  100% {
    width: 1000px;
    height: 1000px;
    opacity: 0;
  }
}


/* Drawers */

.rs-drawer-content {
  border-radius: 0;
  transition: background-image 1s ease-in-out ease-in;
  color:black;
}

.rs-drawer-wrapper {
  background: rgba(9, 8, 7, 0.8);
  border-radius: 0;
  transition: background-image 1s ease-in-out ease-in;
  color: black;
}

.karya-table-row,
.karya-table-row .rs-table-cell {
  background: red;
}

/* Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(9, 8, 7, 1);
  border-radius: 0px;
  background: rgba(9, 8, 7, 1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(9, 8, 7, 1);
  border-radius: 0px;
}

/* Login Styling */

#northStar {
  background-image: url(./Images/bain_logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 7%;
}

.auth_image2 {
  margin: 0 auto;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 1;
  transition: background-image 2s ease-in-out;
  background-image: url(./Images/b716.jpg);
  -webkit-mask-image: -webkit-gradient(linear,
      left,
      right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0)));
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

/*
.auth_image2:hover {
  color: black;
  background-image: url(./Images/b716.jpg);
  background-size: cover;
  transition: background-image 2s ease-in-out;
}

*/

#auth-widget:hover .auth_image {
  background-image: url(./Images/auth1.jpg);
  background-size: cover;
  transition: background-image 2s ease-in-out;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  height: 100vh;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  box-shadow: 41px 41px 82px #848484,
    -41px -41px 82px #ffffff;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 10px;
  padding-top: 0px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.ppt {
  background-image: url(./Images/slide_for_popout.jpg);
  background-size: cover;
  opacity: 1;
  overflow: scroll;
}

/* Table Styling */

.ag-theme-material {
  background-color: transparent;
}

.ag-row-hover {
  background-color: rgba(219, 219, 219, 0.3) !important;
}

.ag-column-hover {
  background-color: rgba(219, 219, 219, 0.3) !important;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
}

.cell-wrap-text {
  white-space: normal !important;
}


.ag-header-row {
  background-color: transparent;
}


/* Header */

.rs-navbar-header {
  background: #000000;
  color: white;
}

.rs-navbar.rs-navbar-inverse {
    background: #000000;
    color: white;
box-shadow: rgba(8, 10, 75, 0.45) 0px 25px 20px -20px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.react-player2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0 auto;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

/* Fractions */

.frac {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0.001em;
  text-align: center;
}

.frac>span {
  display: block;
  padding: 0.3em;
}

.frac span.bottom {
  border-top: thin solid black;
}

.frac span.symbol {
  display: none;
}


/* Login Button */
.logbtn {
  border: none;
  display: block;
  border-radius:12px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  background-color: #b81010;
  padding: 17px 60px;
  margin: 0 auto;
 /* -webkit-box-shadow: inset -22px 9px 5px 5px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: inset -22px 9px 5px 5px rgba(0, 0, 0, 0.41);
    box-shadow: inset -22px 9px 5px 5px rgba(0, 0, 0, 0.41); */
}

.logbtn span {
  position: relative;
  z-index: 1;
}

.logbtn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #000000;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);

}

.logbtn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}


/* Footer styling */

.footer-dark {
  position: absolute;
  width: 100%;
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #000000;
}

.footer-dark .text {
  padding: 0 15px;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
  border-left: 3px solid #eeeeee;
  padding-left: 10px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul li {
  padding: 0 0 .2em 1.0em;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 1.0;
  font-size: 16px;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}

.footer-dark ul.social li {
  padding: 3px 0;
}

.footer-dark ul.social li a i {
  margin-right: 5px;
  font-size: 25px;
  -webkit-transition: .5s all ease;
  -moz-transition: .5s all ease;
  transition: .5s all ease;
}

.footer-dark ul.social li:hover a i {
  font-size: 30px;
  margin-top: -10px;
}

.footer-dark ul.social li a {
  color: #ffffff;
}

.footer-dark ul.social li a:hover {
  color: #eeeeee;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social>a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social>a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

/* Nav Bar */
.navMain {
  -webkit-box-shadow: 0px 14px 59px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 14px 59px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 14px 59px -4px rgba(0, 0, 0, 0.17);
}



/* Card Styling */

.property-card {
  height: 18em;
  width: 14em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 16px;
  overflow: hidden;
  -webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image {
  height: 6em;
  width: 14em;
  padding: 1em 2em;
  position: Absolute;
  top: 0px;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-image: url('https://cdn.photographylife.com/wp-content/uploads/2017/01/What-is-landscape-photography.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

/* Bottom Card Section */

.property-description {
  background-color: #FAFAFC;
  height: 12em;
  width: 14em;
  position: absolute;
  bottom: 0em;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align: center;
}

/* Social Icons */

.property-social-icons {
  width: 1em;
  height: 1em;
  background-color: black;
  position: absolute;
  bottom: 1em;
  left: 1em;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Property Cards Hover States */

.property-card:hover .property-description {
  height: 0em;
  padding: 0px 1em;
}

.property-card:hover .property-image {
  height: 18em;
}

.property-card:hover .property-social-icons {
  background-color: white;
}

.property-card:hover .property-social-icons:hover {
  background-color: blue;
  cursor: pointer;
}



.shadowed{
  padding: 25px;
    -webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
      box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.shadowedButton {
  border-radius: 25px;
background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 2px 2px 4px #a8a8a8,
    -2px -2px 4px #ffffff;

}

.shadowed-panel {
  padding: 25px;
  -webkit-box-shadow: 15px 15px 27px #ffffff, -15px -15px 27px #ffffff;
  box-shadow: 15px 15px 27px #ffffff, -15px -15px 27px #ffffff;
}

.shadowed-panel:h5{
  margin-right: 90vh;
}


/* Video background */
.overlay {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: absolute;
  top: 10%;
  left: 50%;
}

.overlay h1 {
  text-align: center;
  padding-top: 100px;
  color: #fff;
  font-family: inherit;
}

.overlay p {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  color: #fff;
  font-family: inherit;
  margin-bottom: 20px;
}

.overlay a {
  color: #fff;
}

.orange {
  text-decoration: none;
}

p a.orange {
  color: #f27950;
}


/*Homepage */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent !important;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  position: absolute;
  display: none;
}


.content {
  max-width: 50%;
  font-size: 24px;
  margin-top: 10%;
  margin-left: 5%;
  padding: 25px;
}


.loader {
  background-color: rgba(9, 8, 7, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 9;
  transform: translateY(-100%);

  -webkit-animation: loader 1.2s cubic-bezier(0.5, 0.6, 0.2, 1);
  -moz-animation: loader 1.2s cubic-bezier(0.5, 0.6, 0.2, 1);
  animation: loader 1.2s cubic-bezier(0.5, 0.6, 0.2, 1);

  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;

  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes loader {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

@keyframes loader {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.loader2 {
  background-color: rgba(9, 8, 7, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 5;
  transform: translateY(-100%);

  -webkit-animation: loader .8s cubic-bezier(0.5, 0.6, 0.2, 1);
  -moz-animation: loader .8s cubic-bezier(0.5, 0.6, 0.2, 1);
  animation: loader .8s cubic-bezier(0.5, 0.6, 0.2, 1);

  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;

  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes loader {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

@keyframes loader {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(-100%);
  }
}

.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}


.fadeInUp {
  -webkit-animation: fadeInUp 1.6s cubic-bezier(0.2, 0.6, 0.2, 1);
  -moz-animation: fadeInUp 1.6s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation: fadeInUp 1.6s cubic-bezier(0.2, 0.6, 0.2, 1);

  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;

  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.delay {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.animate {
  -webkit-animation: animate 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);
  -moz-animation: animate 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation: animate 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);

  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;

  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.gridVerb {
  text-align: 'left';
}


/* CSS Text Intro */
.cd-intro {
  position: relative;
  height: 100vh;
  width: 100%;
  /* vertically align its content */
  display: table;
  background: rgba(9, 8, 7, 0.8);
  overflow: hidden;
}

.cd-intro-content {
  /* vertically align inside its parent */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd-intro-content h1 {
  font-size: 2.4rem;
  color: #ffffff;
}

.cd-intro-content p {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 1em 0;
  color: #eaedf1;
}

.cd-intro-content .action-wrapper {
  display: inline-block;
}

.cd-intro-content .action-wrapper::after {
  clear: both;
  content: "";
  display: table;
}

.cd-intro-content .action-wrapper>* {
  float: left;
}

@media only screen and (min-width: 768px) {
  .cd-intro-content h1 {
    font-size: 5.2rem;
    font-weight: 300;
  }

  .cd-intro-content p {
    font-size: 1.6rem;
    margin: 1.5em 0 1.9em;
  }
}

.cd-btn {
  display: inline-block;
  padding: 1.2em 1.4em;
  font-size: 1.3rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: #202e4a;
  border-radius: .25em;
  margin-right: 1.5em;
}

.cd-btn:nth-of-type(2) {
  margin-right: 0;
}

.cd-btn.main-action {
  background-color: #fb5e58;
}

@media only screen and (min-width: 480px) {
  .cd-btn {
    padding: 1.2em 1.6em;
  }
}

@media only screen and (min-width: 768px) {
  .cd-btn {
    padding: 1.4em 1.8em;
  }
}

.cd-intro-content h1,
.cd-intro-content h1 span,
.cd-intro-content p,
.cd-intro-content .cd-btn {
  opacity: 0;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.no-cssanimations .cd-intro-content h1,
.no-cssanimations .cd-intro-content h1 span,
.no-cssanimations .cd-intro-content p,
.no-cssanimations .cd-intro-content .cd-btn {
  opacity: 1;
}


/* -------------------------------- 

Mask 2

-------------------------------- */
.mask-2.cd-intro-content * {
  /* overwrite default style */
  opacity: 1;
}

.mask-2.cd-intro-content .content-wrapper {
  position: relative;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding: 2em 0;
  overflow: hidden;
}

.mask-2.cd-intro-content .content-wrapper::before {
  /* vertical bar */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background-color: #ffffff;
  -webkit-animation: cd-pulse 1.3s 0.3s both;
  -moz-animation: cd-pulse 1.3s 0.3s both;
  animation: cd-pulse 1.3s 0.3s both;
}

.no-cssanimations .mask-2.cd-intro-content .content-wrapper::before {
  opacity: 0;
}

.mask-2.cd-intro-content .content-wrapper>div {
  /* wrap the entire content */
  position: relative;
  z-index: 1;
}

.mask-2.cd-intro-content .content-wrapper,
.mask-2.cd-intro-content .content-wrapper>div {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  animation-delay: 1.1s;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.mask-2.cd-intro-content .content-wrapper {
  -webkit-animation-name: cd-mask-wrapper;
  -moz-animation-name: cd-mask-wrapper;
  animation-name: cd-mask-wrapper;
}

.mask-2.cd-intro-content .content-wrapper>div {
  -webkit-animation-name: cd-mask-content;
  -moz-animation-name: cd-mask-content;
  animation-name: cd-mask-content;
}

@-webkit-keyframes cd-mask-wrapper {
  0% {
    -webkit-transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-mask-wrapper {
  0% {
    -moz-transform: translateX(50%);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-mask-wrapper {
  0% {
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-mask-content {
  0% {
    -webkit-transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes cd-mask-content {
  0% {
    -moz-transform: translateX(-100%);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@keyframes cd-mask-content {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-pulse {

  0%,
  30.8% {
    opacity: 0;
  }

  15.4%,
  46.2%,
  61.5%,
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes cd-pulse {

  0%,
  30.8% {
    opacity: 0;
  }

  15.4%,
  46.2%,
  61.5%,
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes cd-pulse {

  0%,
  30.8% {
    opacity: 0;
  }

  15.4%,
  46.2%,
  61.5%,
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* -------------------------------- 

Mask

-------------------------------- */
.mask.cd-intro-content h1 {
  position: relative;
  padding-bottom: 10px;
  /* overwrite default style */
  opacity: 1;
  font-weight: 500;
  /* <h1> text is not visible - it is used only as a container for the ::after element */
  color: transparent;
  overflow: hidden;
}

.mask.cd-intro-content h1::after {
  /* this is the animated text */
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #ffffff;
  -webkit-animation-name: cd-reveal-up;
  -moz-animation-name: cd-reveal-up;
  animation-name: cd-reveal-up;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.mask.cd-intro-content h1 span {
  position: relative;
  display: inline-block;
  opacity: 1;
}

.mask.cd-intro-content h1 span::before {
  /* this is the loading bar */
  content: '';
  position: absolute;
  top: calc(100% + 8px);
  left: -1em;
  height: 2px;
  width: calc(100% + 2em);
  background-color: #ffffff;
  -webkit-animation: cd-loading-mask 1s 0.3s both;
  -moz-animation: cd-loading-mask 1s 0.3s both;
  animation: cd-loading-mask 1s 0.3s both;
}

@media only screen and (min-width: 768px) {
  .mask.cd-intro-content h1 {
    padding-bottom: 20px;
  }

  .mask.cd-intro-content h1 span::before {
    top: calc(100% + 18px);
  }
}

.mask.cd-intro-content p {
  position: relative;
  margin: 0;
  padding: 10px 0 0;
  -webkit-animation-name: cd-reveal-down;
  -moz-animation-name: cd-reveal-down;
  animation-name: cd-reveal-down;
}

@media only screen and (min-width: 768px) {
  .mask.cd-intro-content p {
    padding-top: 20px;
  }
}

.mask.cd-intro-content h1::after,
.mask.cd-intro-content p {
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.mask.cd-intro-content .action-wrapper {
  overflow: hidden;
}

.mask.cd-intro-content .action-wrapper .cd-btn {
  opacity: 1;
  margin: 0 0 0 1.5em;
}

.mask.cd-intro-content .action-wrapper .cd-btn:first-of-type {
  margin-left: 0;
}

@-webkit-keyframes cd-loading-mask {

  0%,
  100% {
    -webkit-transform: scaleX(0);
  }

  40%,
  60% {
    -webkit-transform: scaleX(1);
  }
}

@-moz-keyframes cd-loading-mask {

  0%,
  100% {
    -moz-transform: scaleX(0);
  }

  40%,
  60% {
    -moz-transform: scaleX(1);
  }
}

@keyframes cd-loading-mask {

  0%,
  100% {
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
  }

  40%,
  60% {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }
}


/* Flipcard */

.card {
  border-radius: 20px;
    border-color: white;
}

.flipcard {
  perspective: 1000px;
  margin-bottom: 1.5rem;
      -webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
      box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
}

.flipcard .flipcard-wrap {
    border-radius: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipcard .card-front,
.flipcard .card-back {
      border-radius: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  backface-visibility: hidden;
  transition: all .3s;
}

.flipcard .card-front {
  z-index: 2;
}

.flipcard .card-front::after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-width: 16px 16px 0 0;
  border-style: solid;
  border-color: #999 #fff;
  border-radius: .25rem 0 0 0;
}

.flipcard .card-back {
  position: absolute;
  top: 0;
  text-align: left;
  z-index: 1;
  transform: rotateY(180deg);
  overflow-y: auto;
}

/* the flippy magic */
.flipcard:hover .flipcard-wrap {
  transform: rotateY(-180deg);
}



/* Tabs styling */
.main-presupuestoMensual {
  min-width: 320px;
  max-width: 800px;
  padding: 50px;
  margin: 0 auto;
  background: #fff;
}

.containter-tab1 {
  display: none;
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

.input-tabPresupuestoMensual {
  display: none;
}

.label-tabPresupuestoMensual {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.label-tabPresupuestoMensual:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px;
}

/* .label-tabPresupuestoMensual[for*='1']:before {
  content: '\f1cb';
}

.label-tabPresupuestoMensual[for*='2']:before {
  content: '\f17d';
}

.label-tabPresupuestoMensual[for*='3']:before {
  content: '\f16b';
}

.label-tabPresupuestoMensual[for*='4']:before {
  content: '\f1a9';
} */

.label-tabPresupuestoMensual:hover {
  color: #888;
  cursor: pointer;
}

.input-tabPresupuestoMensual:checked+.label-tabPresupuestoMensual {
  color: #555;
  border: 1px solid #ddd;
  border-top: 2px solid orange;
  border-bottom: 1px solid #fff;
}

#tab1:checked~#content1,
#tab2:checked~#content2,
#tab3:checked~#content3,
#tab4:checked~#content4 {
  display: block;
}

@media screen and (max-width: 650px) {
  .label-tabPresupuestoMensual {
    font-size: 0;
  }

  .label-tabPresupuestoMensual:before {
    margin: 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .label-tabPresupuestoMensual {
    padding: 15px;
  }
}

/**/
body {
  background: #ffffff;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-style: solid;
  border-top-color: #ECD078;
  border-right-color: #C02942;
  border-bottom-color: #542437;
  border-left-color: #53777A;
  content: '';
  transform: translate(-50%, -50%);
  animation: rotate 1.5s infinite ease-in-out;
}

.loader:before {
  border-width: 10vh;
}

.loader:after {
  width: 30vh;
  height: 30vh;
  border-width: 2.5vh;
  animation-direction: reverse;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



/* Table styling */
.ag-theme-material .ag-cell-value {
  line-height: 20px !important;
  word-break: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left !important;
}

/* Sticky div */
div.sticky {
       position: -webkit-sticky;
       /* Safari */
       position: sticky;
     }


